'use client'

import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import SvgArrowRight from 'app/components/Svg/SvgArrowRight'
import SvgClose from 'app/components/Svg/SvgClose'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import {
  AnnouncementBannerFragment,
  GetAnnouncementBannerQuery,
} from '@generated/schema'
import getContentUrl from 'app/utils/functions/getContentUrl'
import styles from './AnnouncementBanner.module.scss'

interface IAnnouncementBannerClientProps {
  announcements: GetAnnouncementBannerQuery
}

export default function AnnouncementBannerClient({
  announcements,
}: IAnnouncementBannerClientProps) {
  const [announcement, setAnnouncement] =
    useState<AnnouncementBannerFragment | null>(null)

  useEffect(() => {
    const announcementData =
      announcements.announcementBannerCollection?.items?.filter(
        (item) =>
          localStorage.getItem(`announcement-banner-${item?.sys.id}`) !==
          'false'
      )?.[0]
    if (announcementData) setAnnouncement(announcementData)
  }, [announcements])

  if (!announcement || !announcement.text || !announcement) return null

  let link = ''
  if (announcement.link?.__typename === 'ComponentHelperExternalLink') {
    link = announcement.link.url || ''
  } else if (announcement.link?.__typename === 'ComponentHelperNavLink') {
    link = getContentUrl(announcement.link.linkTo)
  }

  const handleClose = (): void => {
    setAnnouncement(null)
    localStorage.setItem(`announcement-banner-${announcement.sys.id}`, 'false')
  }

  return createPortal(
    <div
      id={announcement.sys.id}
      role='alert'
      className={`${styles.banner} bg-static-darkblue text-static-white no-print rounded-sm-2 z-index-100 p-sm-fixed py-sm-4 pl-sm-5 pr-sm-6 px-md-6 py-md-5 text-md-center`}
    >
      <p className='f-sm-2 f-md-4'>
        {announcement.text}
        {link && (
          <TrackClick
            data-testid='announcement-banner-link'
            className='tertiary-link text-underline text-bold text-static-white ml-sm-2'
            href={link}
            onClick={() => {
              handleClose()
            }}
            isEngagementEvent={false}
            trackProps={{
              clickType: 'text',
              unitName: 'banner',
              buttonName: 'learn_more',
              isExternal: true,
              unitLocation: 'header',
              linkedContentUrl: `${link}`,
              componentId: null,
            }}
          >
            <TrackImpression
              as='span'
              unitName='banner'
              unitLocation='header'
              buttonName={null}
              linkedContentUrl={link}
              componentId={null}
            />
            {announcement.link?.label}
            <SvgArrowRight className='fill-static-scarlet w-sm-4 ml-sm-1 vertical-align-sm-middle' />
          </TrackClick>
        )}
      </p>
      <TrackClick
        as='button'
        type='button'
        className='Btn-link p-sm-4 p-sm-absolute h-md-full right-sm-0 top-sm-0'
        aria-label='Close notification'
        onClick={() => {
          handleClose()
        }}
        isEngagementEvent
        trackProps={{
          eventType: 'dismiss',
          unitName: 'banner',
          linkedContentUrl: `${link}`,
          unitLocation: 'header',
          componentId: null,
        }}
      >
        <SvgClose className='mr-sm-0 w-sm-4 fill-static-white' />
      </TrackClick>
    </div>,
    document.body
  )
}
