import get from 'lodash/get'

/**
 * @deprecated Use the new getContentUrl in the app folder instead
 */
export const getContentUrl = (
  content,
  overrideType: string | null = null
): string => {
  const type = overrideType || get(content, '__typename') || content?.typename

  switch (type) {
    case 'Generic':
      return content.url || `/${content.slug}`

    case 'Article':
      return content.url || `/articles/${content.slug}`

    case 'Topic':
      return content.url || `/topics/${content.slug}`

    case 'ExternalTag':
      return `/topics/${content.slug}`

    case 'PodcastEpisode': {
      const podcastSlug =
        content.linkedFrom?.podcastCollection.items[0]?.slug ||
        content.podcast?.slug
      return `/podcasts/${podcastSlug}/${content.slug}`
    }
    case 'Podcast':
      return `/podcasts/${content.slug}`

    case 'CarouselItem': {
      const slug =
        content.linkedFrom.carouselCollection.items[0]?.linkedFrom
          .articleCollection.items[0]?.slug
      return slug ? `/articles/${slug}` : ''
    }

    case 'DownloadableAsset':
      return content.asset?.url

    case 'Person':
      return content.slug
        ? `/people/${content.slug}`
        : `/people/${content.sys?.id}`

    case 'PageGeneric':
      return `/${content.slug}`

    case 'PageTopics':
      return `/topics/${content.externalTag?.slug}`

    case 'PageAudiences':
      return `/audiences/${content.slug}`

    case 'PageHome':
      return `/${content.slug}`.replace(/\/\//g, '/')

    case 'Page':
      return `${content.slug}`

    case 'Asset':
      return `${content.url}`

    case 'PressRelease':
      return `/press-releases/${content.slug}`

    case 'ResearchAndSurveys':
      return `/research-and-surveys/${content.reseachSlug}`

    case 'NewsAppearance':
      return `${content.newsAppearanceLinkToPublication}`

    default:
      return ''
  }
}
