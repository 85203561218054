'use client'

import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import SVGAssistantIcon from 'components/assistant/SVGassistant'
import AssistantPopover from './AssistantPopover/AssistantPopover.client'

interface IAssistantIconProps {
  sysId: string
}

export default function AssistantIcon({ sysId }: IAssistantIconProps) {
  return (
    <TrackImpression
      as='div'
      className='p-sm-relative h-sm-full d-sm-flex flex-sm-align-center'
      componentId={sysId}
      buttonName='start_new_conversation'
      unitName='chatbot_entry'
      unitVariation='chatbot_entry_global'
      unitLocation='header'
    >
      <TrackClick
        className='Btn-link flex-sm-align-center flex-sm-justify-center stretched-link icon-hover no-print target'
        data-testid='iconHeader-understood-ai-assistant-link'
        aria-label='Understood AI Assistant'
        title='Understood AI Assistant'
        href='/assistant'
        onClick={() => {
          localStorage.setItem('assistant-popover', 'false')
        }}
        isEngagementEvent={false}
        trackProps={{
          clickType: 'button',
          buttonName: 'start_new_conversation',
          unitName: 'chatbot_entry',
          unitVariation: 'chatbot_entry_global',
          unitLocation: 'header',
          componentId: sysId,
        }}
      >
        <SVGAssistantIcon className='vicon' />
      </TrackClick>

      <AssistantPopover sysId={sysId} />
    </TrackImpression>
  )
}
