import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS, Node } from '@contentful/rich-text-types'
import { NextImage } from '@fractal/primitives/atoms/NextImage'
import { embeddedEntryRenderer } from '@fractal/primitives/molecules/ContentfulEmbeds'
import { getContentUrl } from 'components/shared/utils/getContentUrl'
import * as PropTypes from 'prop-types'
import React from 'react'
import * as ContentfulMarks from '../../ContentfulMarks'
import * as ContentfulBlocks from '../ContentfulBlocks'
import * as ContentfulInline from '../ContentfulInline'
import {
  IRichTextLinks,
  IRichTextProps,
  LinkTextTag
} from './RichText.interface'
import styles from './RichText.module.scss'

const getNodeRenderer = (
  Component: any,
  linkTextTag: string,
  currentComponentTag: LinkTextTag,
  alternate: boolean,
  sourceInfo: boolean,
  unstyled?: boolean
) => (node: Node, children) => {
  let props = {
    ...node.data,
    linkText: linkTextTag === currentComponentTag,
    alternate,
    sourceInfo,
    unstyled
  }
  return <Component {...props}>{children}</Component>
}

const getOptions = (
  linkTextTag: LinkTextTag,
  alternate: boolean,
  sourceInfo: boolean,
  unstyled: boolean,
  richTextLinks?: IRichTextLinks
) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: getNodeRenderer(
      ContentfulBlocks.Paragraph,
      linkTextTag,
      'Paragraph',
      alternate,
      sourceInfo,
      unstyled
    ),
    [BLOCKS.HEADING_1]: getNodeRenderer(
      ContentfulBlocks.Heading1,
      linkTextTag,
      'Heading1',
      alternate,
      sourceInfo
    ),
    [BLOCKS.HEADING_2]: getNodeRenderer(
      ContentfulBlocks.Heading2,
      linkTextTag,
      'Heading2',
      alternate,
      sourceInfo
    ),
    [BLOCKS.HEADING_3]: getNodeRenderer(
      ContentfulBlocks.Heading3,
      linkTextTag,
      'Heading3',
      alternate,
      sourceInfo
    ),
    [BLOCKS.HEADING_4]: getNodeRenderer(
      ContentfulBlocks.Heading4,
      linkTextTag,
      'Heading4',
      alternate,
      sourceInfo
    ),
    [BLOCKS.HEADING_5]: getNodeRenderer(
      ContentfulBlocks.Heading5,
      linkTextTag,
      'Heading5',
      alternate,
      sourceInfo
    ),
    [BLOCKS.HEADING_6]: getNodeRenderer(
      ContentfulBlocks.Heading6,
      linkTextTag,
      'Heading6',
      alternate,
      sourceInfo
    ),
    [BLOCKS.UL_LIST]: (node: Node, children) => (
      <ContentfulBlocks.UnorderedList {...node.data}>
        {children}
      </ContentfulBlocks.UnorderedList>
    ),
    [BLOCKS.OL_LIST]: (node: Node, children) => (
      <ContentfulBlocks.OrderedList {...node.data}>
        {children}
      </ContentfulBlocks.OrderedList>
    ),
    [BLOCKS.LIST_ITEM]: (node: Node, children) => (
      <ContentfulBlocks.ContentfulListItem {...node.data}>
        {children}
      </ContentfulBlocks.ContentfulListItem>
    ),
    [BLOCKS.QUOTE]: (node: Node, children) => (
      <ContentfulBlocks.Quote {...node.data}>{children}</ContentfulBlocks.Quote>
    ),
    [BLOCKS.HR]: () => <ContentfulBlocks.HorizontalRule />,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const assetId = node?.data?.target?.sys?.id
      const image = richTextLinks?.assets.block.find(b => b.sys.id === assetId)
      if (image) {
        return (
          <div className='mx-sm-auto my-sm-3 my-md-4 p-sm-relative'>
            <NextImage
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.title}
            />
          </div>
        )
      }
      return null
    },
    [INLINES.HYPERLINK]: (node: Node, children) => (
      <ContentfulInline.Hyperlink url={node.data.uri}>
        {children}
      </ContentfulInline.Hyperlink>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
      const embed = richTextLinks?.entries?.block?.filter(b => b.sys.id === node?.data?.target?.sys?.id)?.[0]
      return embeddedEntryRenderer(
        node,
        node?.data?.target?.sys?.contentType?.sys?.id || embed?.type
      )
    },
    [INLINES.ENTRY_HYPERLINK]: (node: Node, children) => {
      const entryId = node?.data?.target?.sys?.id
      const entry = richTextLinks?.entries?.hyperlink?.find(b => b?.sys?.id === entryId)
      if (!entry && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') console.error(`Missing entry_hyperlink on entry: ${entryId}`)
      const url = getContentUrl(entry) ?? `${node?.data?.target?.fields?.path}/${node?.data?.target?.fields?.slug}`

      return (
        <ContentfulInline.Hyperlink url={url}>
          {children}
        </ContentfulInline.Hyperlink>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node: Node, children) => {
      return embeddedEntryRenderer(
        node,
        node?.data?.target?.sys?.contentType?.sys?.id
      )
    }
  },
  renderMark: {
    [MARKS.BOLD]: (text: string) => (
      <ContentfulMarks.Bold type={'bold'}>{text}</ContentfulMarks.Bold>
    ),
    [MARKS.ITALIC]: (text: string) => (
      <ContentfulMarks.Italics type={'italics'}>{text}</ContentfulMarks.Italics>
    ),
    [MARKS.UNDERLINE]: (text: string) => (
      <ContentfulMarks.Underline type={'underline'}>
        {text}
      </ContentfulMarks.Underline>
    )
  }
})

function RichText({
  richTextContent,
  richTextLinks,
  linkTextTag,
  alternate,
  sourceInfo,
  unstyled
}: IRichTextProps) {
  linkTextTag = linkTextTag || 'None'
  alternate = alternate || false
  sourceInfo = sourceInfo || false
  unstyled = unstyled || false

  if (!richTextContent
    || Object.entries(richTextContent).length === 0
    || richTextContent.constructor !== Object
  ) {
    return null
  }

  return (
    <div data-testid='richText' className={styles.container}>
      {documentToReactComponents(
        richTextContent,
        getOptions(
          linkTextTag,
          alternate,
          sourceInfo,
          unstyled,
          richTextLinks
        )
      )}
    </div>
  )
}

RichText.propTypes = {
  richTextContent: PropTypes.object.isRequired,
  linkTextTag: PropTypes.string
}

export { RichText }
export default RichText
