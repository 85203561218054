import React from 'react'


export const DoubleTheDonation = () => {
  return (
    <iframe
      style={{ border: 'none' }}
      className='w-sm-full'
      src={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/matching-gifts-embed.html`} />
  )
}

export default DoubleTheDonation
