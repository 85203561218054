import { ISvgProps } from './Svg.interface'

export default function SvgCaretDown({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-caret-down'
      viewBox='0 0 12 12'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.68005 4.44202L2.52005 3.6001L6.11405 7.20706L9.71981 3.6001L10.5601 4.44202L6.96653 8.0485C6.50117 8.5165 5.74229 8.51746 5.27501 8.05066L1.68005 4.44202Z'
        stroke='currentColor'
      />
    </svg>
  )
}
