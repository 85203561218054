'use client'

import { ComponentHelperNavLinkGroupFragment } from '@generated/schema'
import SvgCaretDown from 'app/components/Svg/SvgCaretDown'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { usePathname } from 'next/navigation'
import styles from './NavbarPanel.module.scss'
import PanelGroup from './PanelGroup/PanelGroup'

interface INavbarPanelClientProps {
  navbarItem: ComponentHelperNavLinkGroupFragment
}

function NavbarPanel({ navbarItem }: INavbarPanelClientProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const pathname = usePathname()

  useEffect(() => {
    setIsHovering(false)
  }, [pathname])

  if (!navbarItem) return null

  function handleBlur(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsHovering(false)
    }
  }

  return (
    <div
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onBlur={handleBlur}
      className={`${styles.container} h-sm-full`}
    >
      <div className='d-sm-flex flex-sm-align-center h-sm-full'>
        <TrackClick
          as='button'
          className='Btn-link f-sm-4 text-regular d-sm-flex flex-sm-align-center'
          isEngagementEvent={false}
          onClick={() => {
            setIsHovering(true)
          }}
          trackProps={{
            clickType: 'text',
            isContent: false,
            linkText: navbarItem.title,
            linkedContentId: navbarItem.sys.id,
            unitName: 'main_menu',
            unitLocation: 'header',
            componentId: navbarItem.sys.id,
          }}
        >
          <div className={styles.button} data-text={navbarItem.title}>
            {navbarItem.title}
          </div>
          <SvgCaretDown className='w-sm-3 ml-sm-1' />
        </TrackClick>
      </div>
      {isHovering && (
        <div
          className={`${styles.panelContainer} p-sm-absolute z-index-100 w-sm-full left-sm-0 bg-background-page-white`}
        >
          <div className='pt-sm-5 pb-sm-6 border-shadow border-sm-top border-sm-bottom border-foreground-grey2'>
            <div className='container grid'>
              {navbarItem.description && (
                <div
                  className={`grid-col-md-6 f-sm-3 pr-md-7 ${styles.markdownContainer}`}
                >
                  <ReactMarkdown>{navbarItem.description}</ReactMarkdown>
                </div>
              )}

              {/* render subgroups */}
              {navbarItem.itemsCollection?.items.map((item) => {
                if (item?.__typename === 'ComponentHelperNavLinkGroup') {
                  return (
                    <PanelGroup
                      group={item as ComponentHelperNavLinkGroupFragment}
                      componentId={navbarItem.sys.id}
                    />
                  )
                }

                return null
              })}

              {/* render itself, as it can also links */}
              <PanelGroup
                group={navbarItem}
                componentId={navbarItem.sys.id}
                splitColumns
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavbarPanel
