export const newslettersEn = [
  {
    label: 'Families',
    value: 'families',
    subLabel: "I'm a parent or caregiver, and I want my child to thrive.",
  },
  {
    label: 'Educators',
    value: 'educators',
    subLabel: "I'm an educator and I want my students to thrive.",
  },
  {
    label: 'Young Adults',
    value: 'ya',
    subLabel: "I'm a young adult, and I want to shape my own journey.",
  },
]

export const newslettersEs = [
  {
    label: 'Familias',
    value: 'families',
    subLabel: '',
  },
]
