'use client'

import SVGclose from '@/journey/Svgs/icons/close'
import SVGdoubleStar from '@/journey/Svgs/icons/doubleStar'
import { useLocalStorage } from '@context/hooks/useStorage'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import styles from './AssistantPopover.module.scss'

interface IAssistantPopoverProps {
  sysId: string
}

export default function AssistantPopover({ sysId }: IAssistantPopoverProps) {
  const [open, setOpen] = useLocalStorage('assistant-popover', true)

  if (!open) return null

  return (
    <div
      role='alert'
      className={`${styles.popover} border-sm-1 border-static-white shadow z-index-100 f-sm-3 text-wrap p-sm-absolute rounded-sm-2 bg-background-high-white p-sm-4`}
    >
      <button
        aria-label='close'
        type='button'
        className='p-sm-absolute Btn-link right-sm-3 top-sm-3'
        onClick={() => {
          setOpen(false)
        }}
      >
        <SVGclose className='vicon' width={12} />
      </button>
      Search and ask questions with the Understood Assistant.
      <div className='mt-sm-2'>
        <TrackClick
          href='/assistant'
          onClick={() => {
            setOpen(false)
          }}
          isEngagementEvent={false}
          trackProps={{
            clickType: 'button',
            buttonName: 'ai_assistant',
            unitName: 'main_menu_popover',
            unitLocation: 'header',
            componentId: sysId,
          }}
        >
          Give it a try{' '}
          <SVGdoubleStar
            width={20}
            className='rotate-90 fill-foreground-midblue'
          />
        </TrackClick>
      </div>
    </div>
  )
}
