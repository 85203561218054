import { Node } from '@contentful/rich-text-types'
import React from 'react'
import { AnchorTag } from '../../../features/AnchorTag'
import { ExternalContent } from '../../../features/ExternalContent'
import { YoutubeVideo } from '../../../features/YoutubeVideo'
import Download from './ContentfulDownload'
import DoubleTheDonation from './DoubleTheDonation'
import TinyTout from './Tinytout'
import QAblock from './qablock'
import Table from './table'

const componentsMap: { [key: string]: any } = {
  YoutubeVideo,
  ExternalContent,
  AnchorTag
}

function EmbeddedGenericContent(props: { node: any }) {
  const type = props.node?.data?.target?.fields?.type || {}
  const contents = props.node?.data?.target?.fields?.contents || {}
  const Component = componentsMap[type] || null
  // Return the actual component, or null
  return Component ? <Component {...contents} /> : null
}

export function embeddedEntryRenderer(node: Node, componentType: string) {
  //TODO:  person, videoblock
  switch (componentType) {
    case 'alloyEditor':
      return <Table table={node?.data?.target?.fields?.content} />
    case 'tinyTout':
      return <TinyTout {...node?.data?.target?.fields?.content} />
    case 'qaBlock':
      return <QAblock {...node?.data?.target?.fields?.content} />
    case 'downloadableAsset':
      return <Download {...node?.data?.target?.fields?.content} />
    case 'genericContent':
      return <EmbeddedGenericContent node={node} />
    case 'Double the donation':
      return <DoubleTheDonation />
    default:
      return null
  }
}
