import * as React from 'react'
import { ISVGsProps } from '../types'

function SVGdoubleStar({
  className = '',
  width = '31',
  height = '',
  fill = 'none'
}: ISVGsProps) {
  return (
    <svg
      viewBox='0 0 31 27'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      {/* eslint-disable-next-line max-len */}
      <path d='M20.5204 8.19066C20.8807 7.33228 22.2352 7.33228 22.5955 8.19066C23.3435 9.97416 24.4049 12.1659 25.4659 13.2165C26.5335 14.2735 28.6594 15.3306 30.3684 16.0721C31.2105 16.4375 31.2105 17.7334 30.3684 18.0988C28.6594 18.8401 26.5335 19.8972 25.4659 20.9542C24.4049 22.0048 23.3435 24.1965 22.5955 25.98C22.2352 26.8384 20.8807 26.8384 20.5204 25.98C19.7724 24.1965 18.711 22.0048 17.65 20.9542C16.5886 19.9037 14.3747 18.8531 12.5732 18.1124C11.7064 17.7559 11.7064 16.4149 12.5732 16.0584C14.3747 15.3176 16.5886 14.267 17.65 13.2165C18.711 12.1659 19.7724 9.97416 20.5204 8.19066Z' />
      {/* eslint-disable-next-line max-len */}
      <path d='M4.86598 1.05801C5.27725 0.171419 6.72099 0.171419 7.13226 1.05801C7.59918 2.06455 8.1622 3.08617 8.72517 3.64562C9.29563 4.21254 10.2992 4.77943 11.2733 5.24704C12.1396 5.66293 12.1396 7.04639 11.2733 7.46224C10.2992 7.92981 9.29563 8.49669 8.72517 9.06358C8.1622 9.62303 7.59918 10.6446 7.13226 11.6512C6.72099 12.5378 5.27725 12.5378 4.86598 11.6512C4.39901 10.6446 3.83604 9.62303 3.27307 9.06358C2.71009 8.50413 1.68204 7.94468 0.669154 7.48069C-0.223024 7.07199 -0.223065 5.63729 0.669113 5.22859C1.682 4.76455 2.71009 4.20506 3.27307 3.64562C3.83604 3.08617 4.39901 2.06455 4.86598 1.05801Z' />
    </svg>
  )
}

export default SVGdoubleStar
