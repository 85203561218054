import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/journey/components/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/prose/fractal/features/ExternalContent/ArticleIframe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/prose/fractal/features/YoutubeVideo/YoutubeVideo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/prose/fractal/primitives/atoms/RichText/RichText.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/AnnouncementBanner/AnnouncementBanner.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Footer/components/Newsletter/Newsletter.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navbar/AssistantIcon/AssistantIcon.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Navbar/Navbar.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navbar/NavbarPanel/NavbarPanel.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Navbar/NavbarSidePanel/NavbarSidePanel.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/TrackClick/TrackClick.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/TrackImpression/TrackImpression.client.tsx");
