'use client'

import SVGemail from '@/journey/Svgs/icons/email'
import RichText from '@fractal/primitives/atoms/RichText/RichText'
import { Checkbox } from '@fractal/primitives/atoms/forms/Checkbox'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import FormError from '@fractal/primitives/atoms/forms/FormError/FormError'
import Label from '@fractal/primitives/atoms/forms/Label/Label'
import useTranslation from 'app/i18n/client'
import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import { newslettersEn, newslettersEs } from './Newsletter.data'
import styles from './Newsletter.module.scss'
import { NewsletterProps } from './Newsletter.type'

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
}

const formItemVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

export default function Newsletter({
  title,
  tags = [],
  privacyPolicy,
}: NewsletterProps) {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const emailInput = useRef<HTMLInputElement>(null)
  const globalContext = useGlobalContext()
  const newsletters =
    globalContext.locale === 'en' ? newslettersEn : newslettersEs
  const [newsLetterError, toggleNewsLetterError] = useState(false)
  const [data, setData] = useState<string[]>(
    newsletters.length === 1 ? [newsletters[0].value] : []
  )

  const handleProgramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const tempData = [...data]

    const index = data?.indexOf(value)

    if (index >= 0) {
      tempData.splice(index, 1)
    } else {
      tempData.push(value)
    }

    if (tempData.length > 0) {
      toggleNewsLetterError(false)
    }

    setData(tempData)
  }

  const submitForm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

    let submit: any
    if (emailError || !isValidEmail || !data.length) {
      submit = false
    } else {
      submit = true
    }

    trackEngagement({
      eventType: 'subscribe',
      submittedText: email,
      submitSuccess: submit,
      unitName: 'email_submit',
      unitLocation: 'inline',
      componentId: null,
    })

    if (!isValidEmail) {
      setEmailError(true)
      emailInput.current?.focus?.()
      return
    }

    if (!data.length) {
      toggleNewsLetterError(true)
      return
    }

    const isEducators = globalContext.slug.indexOf('/educators') > -1
    const selectedRoles: any = Array.from(
      new Set(
        []
          .concat(tags as any)
          .concat(data as any)
          .concat(isEducators ? (['educators'] as any) : [])
      )
    ).filter((a) => !!a)

    await axios({
      url: `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${globalContext.locale}/subscribe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
        tag: tags || [],
        web_user_selected_roles: selectedRoles,
        programs: data,
        join_source: 'js_site_footer',
        join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${globalContext.slug}`,
      },
    }).then(() => {
      setSuccess(true)
    })
  }

  return (
    <div className='p-sm-relative'>
      <AnimatePresence>
        {success && (
          <motion.div
            role='status'
            initial='hidden'
            animate='show'
            variants={containerVariants}
            data-testid='newsletter-success'
            className={`text-foreground-midblue f-sm-6 p-sm-absolute right-sm-0 bottom-sm-0
              top-sm-0 left-sm-0 d-sm-flex flex-sm-justify-center flex-sm-align-center
              z-index-2 bg-background-high-white p-sm-6 mx-sm-n1`}
          >
            {t('newsletter-success')}
          </motion.div>
        )}
      </AnimatePresence>
      <h3 className='f-sm-6 f-md-7 mb-sm-5'>{title}</h3>
      <form onSubmit={submitForm} noValidate className='text-static-cream'>
        <FormControl>
          <Label
            className={styles.staticCreamTextColor}
            htmlFor='footer-email-input'
          >
            {t('email-address')}
          </Label>
          <input
            id='footer-email-input'
            type='email'
            value={email}
            className='form-control'
            required
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setShowForm(true)}
            disabled={success}
            data-testid='footer-email-input'
            ref={emailInput}
            aria-invalid={emailError ? true : undefined}
          />
          <FormError className={styles.staticCreamTextColor}>
            {emailError ? t('save-for-later-error-message') : ''}
          </FormError>
        </FormControl>
        <AnimatePresence>
          {showForm && newsletters.length > 1 && (
            <motion.div
              initial='hidden'
              animate='show'
              variants={containerVariants}
            >
              <motion.p
                className='mt-sm-5 mb-sm-4 f-sm-3 text-regular'
                variants={formItemVariants}
              >
                {t('newsletter-select-label')}
              </motion.p>

              {newsletters.length > 1 &&
                newsletters.map((newsletter) => (
                  <FormControl
                    key={newsletter.value}
                    // @ts-ignore
                    as={motion.div}
                    variants={formItemVariants}
                  >
                    <Label
                      className={`${styles.checkboxLabel} ${styles.staticCreamTextColor}`}
                      size='default'
                      marginSize='default'
                      weight='default'
                      labelType='default'
                      required={false}
                    >
                      <div
                        className={`d-sm-flex flex-sm-column ml-sm-1 vertical-align-sm-middle ${styles.label}`}
                      >
                        <span className='d-sm-block f-sm-4 text-bold mt-sm-1'>
                          {newsletter.label}
                        </span>
                        {newsletter.subLabel && (
                          <span className='d-sm-block f-sm-3'>
                            {newsletter.subLabel}
                          </span>
                        )}
                      </div>
                    </Label>
                    <Checkbox
                      disabled={success}
                      value={newsletter.value}
                      checked={data?.includes(newsletter.value)}
                      onChange={handleProgramChange}
                    />
                  </FormControl>
                ))}

              <FormError>
                {newsLetterError ? t('newsletter-select-error') : ''}
              </FormError>
            </motion.div>
          )}
        </AnimatePresence>
        <button
          type='submit'
          className='Btn Btn--primary flex-sm-align-center'
          disabled={success}
          data-testid='footer-newsletter-signup-submit'
        >
          <SVGemail className='fill-static-darkblue mr-sm-1' />
          {t('subscribe')}
        </button>
        {(showForm || newsletters.length === 0) && (
          <div
            className={`mt-sm-5 f-sm-1 text-regular ${styles.privacyPolicy}`}
            id='emailTerms_footer'
          >
            <RichText richTextContent={privacyPolicy.json} unstyled />
          </div>
        )}
      </form>
    </div>
  )
}
