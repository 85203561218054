import { ComponentHelperNavLinkGroupFragment } from '@generated/schema'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import getContentUrl from 'app/utils/functions/getContentUrl'
import styles from './PanelGroup.module.scss'

interface IPanelGroupProps {
  group: ComponentHelperNavLinkGroupFragment
  componentId: string
  splitColumns?: boolean
}

export default function PanelGroup({
  group,
  componentId,
  splitColumns,
}: IPanelGroupProps) {
  const links = group.itemsCollection?.items.filter(
    (item) =>
      item?.__typename === 'ComponentHelperNavLink' ||
      item?.__typename === 'ComponentHelperExternalLink'
  )
  if (!links?.length) return null

  return (
    <div
      className={`${
        splitColumns ? 'grid-col-md-6' : 'grid-col-md-3'
      } d-sm-flex flex-sm-column`}
    >
      {!splitColumns && (
        <div className='mb-sm-3 f-sm-3 text-bold'>{group.title}</div>
      )}
      <ul
        aria-label={group.title || ''}
        className={`${styles.listContainer} ${
          splitColumns ? styles.listContainerSplitted : ''
        } list-style-none m-sm-0 p-sm-0`}
      >
        {links.map((link, index) => {
          const url =
            link.__typename === 'ComponentHelperExternalLink'
              ? link.url
              : getContentUrl(link.linkTo)

          const customStyle =
            link.__typename === 'ComponentHelperNavLink' &&
            link.style === 'underline'
              ? 'text-underline'
              : 'no-underline'

          if (!url) return null

          return (
            <li key={link.sys.id}>
              <TrackClick
                href={url}
                className={`${customStyle} hover-underline f-sm-3 text-regular text-foreground-midblueShade`}
                isEngagementEvent={false}
                trackProps={{
                  clickType: 'text',
                  isContent: true,
                  linkText: link.label,
                  linkedContentId: link.sys.id,
                  linkedContentUrl: url,
                  unitName: 'main_menu',
                  unitLocation: 'header',
                  positionInUnit: index + 1,
                  componentId,
                }}
              >
                {link.label}
              </TrackClick>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
